import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The sun sets as time passes by`}<br parentName="p"></br>{`
`}{`And I let out a relieved sigh.`}<br parentName="p"></br>{`
`}{`As the colors flood out through my window,`}<br parentName="p"></br>{`
`}{`I wait for the darkness to creep and grow.`}</p>
    <p>{`The night is here, the moon shines bright`}<br parentName="p"></br>{`
`}{`And shooting stars fly by like kites.`}<br parentName="p"></br>{`
`}{`I can turn around and head to my room`}<br parentName="p"></br>{`
`}{`And close my eyes, to dream away the gloom.`}</p>
    <p>{`When I sleep, I enter a world in which,`}<br parentName="p"></br>{`
`}{`the people can breathe again and the soil’s rich.`}<br parentName="p"></br>{`
`}{`A place where trees grow at every corner`}<br parentName="p"></br>{`
`}{`And humanity keeps on moving forward.`}</p>
    <p>{`A place where nobody needs to starve`}<br parentName="p"></br>{`
`}{`So that people far away can still thrive.`}<br parentName="p"></br>{`
`}{`Everyone can learn about the world and,`}<br parentName="p"></br>{`
`}{`No one can take away the people’s land.`}</p>
    <p>{`A place where people can speak their minds`}<br parentName="p"></br>{`
`}{`And live to tell the tale of mankind.`}<br parentName="p"></br>{`
`}{`Where parents don’t need to hide their children`}<br parentName="p"></br>{`
`}{`From the forces who now, are our everyday villains!`}</p>
    <p>{`A place in which all conflict can cease,`}<br parentName="p"></br>{`
`}{`So that everyone can live in peace.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      